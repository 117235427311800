import dayjs from 'dayjs'

const enviaDocumentoUsecase =
  (repository, setDoctoCandidato) => async (documento) => {
    try {
      const payload = { ...documento }

      if (payload.formData[0]) {
        await repository(
          payload.codCandidato,
          payload.codTipoDocto,
          payload.dtIniVal,
          payload.formData[0]
        )

        delete payload.formData
        payload.dtEnvio = dayjs().format('YYYY-MM-DD')

        await setDoctoCandidato(payload)
      }
    } catch (error) {
      throw error
    }
  }

export default enviaDocumentoUsecase
