<template>
  <v-app>
    <v-app-bar
      color="primary"
      app
      v-if="!animacaoMobile"
    >
      <v-img
        width="50"
        src="/cgi.png"
      ></v-img>
    </v-app-bar>
    <v-main>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          v-if="animacaoMobile"
        >
          <v-card
            style="border-radius: 0"
            color="primary"
            width="100%"
            height="100vh"
          >
            <div class="d-flex flex-column justify-center align-center fill-height">

              <div style="width: 30%">
                <v-img
                  cover
                  width="100%"
                  src="/logo-grande.png"
                ></v-img>
              </div>

              <div
                id="frase"
                class="text-center font-weight-bold text-h5 mt-10"
              ></div>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          v-if="animacaoInicial"
        >
          <v-container>
            <div v-if="documentos.length > 0">
              <v-row
                justify="center"
                class="my-5"
              >
                <v-col>

                  <div
                    id="frase-usuario"
                    class="text-center font-weight-bold text-h5"
                  ></div>
                </v-col>
              </v-row>
              <v-row
                justify="center"
                class="mt-5"
                v-if="animacao"
                transition="scale-transition"
              >
                <v-col>
                  <v-card>
                    <v-card-text>
                      
                      <v-file-input
                        :loading="documento.carregar"
                        v-for="(documento, index) in documentos"
                        :key="index"
                        :label="documento.descrDocumento"
                        v-model="documento.formData"
                      ></v-file-input>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <div
              v-else
              class="mt-15 text-center font-weight-bold text-h4"
            >{{ mensagemTela }}</div>
          </v-container>
        </v-col>
      </v-row>
    </v-main>
    <v-btn
      v-if="animacao"
      @click="salvar"
      style="position: fixed; right: 10px; bottom: 10px"
      icon="mdi-content-save"
      color="primary"
    >
    </v-btn>
    <v-snackbar
      color="green"
      v-model="snackbar"
    >
      <div class="white--text">{{ mensagem }}</div>
    </v-snackbar>
  </v-app>
</template>

<script setup>
import { candidatoControllerImpl } from "../di/di";
const {
  salvar,
  animacaoMobile,
  documentos,
  candidato,
  animacao,
  animacaoInicial,
  snackbar,
  mensagem,
  carregando,
  mensagemTela
} = candidatoControllerImpl();
</script>