// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const cgi = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#050923',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}


const jan = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#000000',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const uniao = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#b92329',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const rica = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#ffb81c',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const usipe = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#21B17E',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const redepicapau = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#FCE51F',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const granja = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#DB2517',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const agrosys = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#FFFFFF',
    primary: '#365973',
    'primary-darken-1': '#365973',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

export default createVuetify({
  theme: {
    defaultTheme: process.env.VUE_APP_TEMPLATE,
    themes: {
      cgi,
      jan,
      uniao,
      granja,
      redepicapau,
      usipe,
      rica,
      agrosys,
    }
  },
  defaults: {
    VFileInput: {
      variant: 'underlined',
      chips: true,
      dense: true,
      showSize: true,
      counter: true
    },
  },
})