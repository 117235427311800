import requisicaoPadrao from '@/core/domain/usecase/requisicaoPadrao'

const setDoctoCandidatoRepository = (axios) => async (documento) => {
  try {
    const response = await axios.put(
      '/rest/app/app',
      requisicaoPadrao({
        metodo: 'setDoctoCandidato',
        valor: documento,
      })
    )

    if (response.data.logErroApp) {
       throw response.data.logErroApp[0].erro
    }
  } catch (error) {
    throw error
  }
}

export default setDoctoCandidatoRepository