import requisicaoPadrao from '@/core/domain/usecase/requisicaoPadrao'

const buscaCandidatoRepository = (axios) => async (codCandidato) => {
  try {
    const response = await axios.put(
      '/rest/app/app',
      requisicaoPadrao({
        metodo: 'getCandidato',
        valor: { codCandidato: codCandidato },
      })
    )

    if (response.data.logErroApp) {
      throw response.data.logErroApp[0].erro
    }

    return response.data?.candidato?.[0] ?? {}
  } catch (error) {
    throw error
  }
}

export default buscaCandidatoRepository
