import requisicaoPadrao from '@/core/domain/usecase/requisicaoPadrao'
import Documento from '../../domain/model/documento'

const buscaDocumentosRepository = (axios) => async (codCandidato) => {
  try {
    const response = await axios.put(
      '/rest/app/app',
      requisicaoPadrao({
        metodo: 'getDoctoCandidato',
        valor: { codCandidato: codCandidato },
      })
    )

    return (
      response.data?.documento?.map((documento) => new Documento(documento)) ??
      []
    )
  } catch (error) {
    throw error
  }
}

export default buscaDocumentosRepository
