import axios from 'axios'
import candidatoController from '../controller/candidatoController'
import buscaCandidatoRepository from '../data/repository/buscaCandidatoRepository'
import buscaDocumentosRepository from '../data/repository/buscaDocumentosRepository'
import enviaDocumentoRepository from '../data/repository/enviaDocumentoRepository'
import setDoctoCandidatoRepository from '../data/repository/setDoctCandidatoRepository'
import buscaCandidatoUsecase from '../domain/usecase/buscaCandidatoUsecase'
import buscaDocumentosUsecase from '../domain/usecase/buscaDocumentosUsecase'
import enviaDocumentoUsecase from '../domain/usecase/enviaDocumentoUsecase'

const axiosIntance = axios.create({
  //   baseURL: 'http://192.168.200.20:28610',
  baseURL: process.env.VUE_APP_URL_API,
})

const buscaDocumentosRepositoryImpl = buscaDocumentosRepository(axiosIntance)
const buscaDocumentosUsecaseImpl = buscaDocumentosUsecase(
  buscaDocumentosRepositoryImpl
)
const buscaCandidatoRepositoryImpl = buscaCandidatoRepository(axiosIntance)
const buscaCandidatoUsecaseImpl = buscaCandidatoUsecase(
  buscaCandidatoRepositoryImpl
)

const setDoctoCandidatoRepositoryImpl =
  setDoctoCandidatoRepository(axiosIntance)
const enviaDocumentoRepositoryImpl = enviaDocumentoRepository(axiosIntance)
const enviaDocumentoUsecaseImpl = enviaDocumentoUsecase(
  enviaDocumentoRepositoryImpl,
  setDoctoCandidatoRepositoryImpl
)

const candidatoControllerImpl = candidatoController(
  buscaDocumentosUsecaseImpl,
  buscaCandidatoUsecaseImpl,
  enviaDocumentoUsecaseImpl
)

export { candidatoControllerImpl }
