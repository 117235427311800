const requisicaoPadrao = ({ programa = '', metodo = '', valor = {} }) => {
  return {
    uid: '',
    pwd: '',
    ptoP: programa === '' ? 'recruta/candidato.p' : programa,
    params: [
      {
        parametro: 'pcMetodo',
        data_type: 'char',
        param_type: 'input',
        valor: metodo,
      },
      {
        parametro: 'pcParametros',
        data_type: 'longchar',
        param_type: 'input',
        valor: JSON.stringify(valor),
      },
      {
        parametro: 'pcRetorno',
        data_type: 'longchar',
        param_type: 'output',
        valor: '',
      },
    ],
  }
}

export default requisicaoPadrao
