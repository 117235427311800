const enviaDocumentoRepository =
  (axios) => async (codCandidato, codTipoDocto, dtIniVal, arquivo) => {
    try {
      var formData = new FormData()
      formData.append('files', arquivo)

      const url = encodeURI(
        `/web/DownloadUploadFiles?tabela=candidatoListaDocto&campo=anexo&chave=${codCandidato}|${codTipoDocto}|${dtIniVal}`
      )

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error) {
      throw error
    }
  }

export default enviaDocumentoRepository
