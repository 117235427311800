class Documento {
  constructor({
    codCandidato = 0,
    nomeCandidato = '',
    codEmp = 0,
    codFil = 0,
    codCargo = 0,
    descrCargo = '',
    codUnidade = 0,
    nomeUnidade = '',
    dtIniVal = '',
    codTipoDocto = 0,
    descrDocumento = '',
    dtIniValDetalhe = '',
    dtLimite = '',
    dtEnvio = null,
    formData = [],
    carregar = false
  }) {
    this.codCandidato = codCandidato
    this.nomeCandidato = nomeCandidato
    this.codEmp = codEmp
    this.codFil = codFil
    this.codCargo = codCargo
    this.descrCargo = descrCargo
    this.codUnidade = codUnidade
    this.nomeUnidade = nomeUnidade
    this.dtIniVal = dtIniVal
    this.codTipoDocto = codTipoDocto
    this.descrDocumento = descrDocumento
    this.dtIniValDetalhe = dtIniValDetalhe
    this.dtLimite = dtLimite
    this.dtEnvio = dtEnvio
    this.formData = formData
    this.carregar = carregar
  }
}

export default Documento
