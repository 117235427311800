import { Promise } from 'core-js'
import Typed from 'typed.js'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify/lib/framework'

const candidatoController =
  (buscaDocumentosUsecase, buscaCandidatoUsecase, enviaDocumentoUsecase) =>
  () => {
    const documentos = ref([])
    const candidato = ref({})
    const animacao = ref(false)
    const animacaoInicial = ref(false)
    const animacaoMobile = ref(true)
    const snackbar = ref(false)
    const mensagem = ref('Dados salvos com sucesso!')
    const mensagemTela = ref('Candidato não encontrado!')
    const route = useRoute()
    const { mobile } = useDisplay()

    onMounted(async () => {
      try {
        new Typed('#frase', {
          strings: ['RH DIGITAL <br> ENVIO DE DOCUMENTOS'],
          showCursor: false,
          typeSpeed: 80,
          onComplete: () => {
            animacaoInicial.value = true

            if (mobile.value) {
              animacaoMobile.value = false
            }

            setTimeout(() => {
              new Typed('#frase-usuario', {
                strings: [candidato.value.mensagem],
                showCursor: false,
                typeSpeed: 20,
                onComplete: () => {
                  animacao.value = true
                },
              })
            }, 200)
          },
        })

        await buscaInformacoes()
      } catch (error) {
        mensagemTela.value = error
      }
    })

    const buscaInformacoes = async () => {
      const [documentosRetorno, candidatoRetorno] = await Promise.all([
        buscaDocumentosUsecase(route.params.candidato),
        buscaCandidatoUsecase(route.params.candidato),
      ])

      documentos.value = documentosRetorno
      candidato.value = candidatoRetorno
    }

    const salvar = async () => {
      try {
        for (let documento of documentos.value) {
          documento.carregar = true
          await enviaDocumentoUsecase(documento)
          documento.carregar = false
        }

        snackbar.value = true
        await buscaInformacoes()
      } catch (error) {
        mensagemTela.value = error
      }
    }

    return {
      documentos,
      candidato,
      animacao,
      animacaoInicial,
      animacaoMobile,
      salvar,
      snackbar,
      mensagem,
      mensagemTela
    }
  }

export default candidatoController
