import { createRouter, createWebHistory } from 'vue-router'
import CandidatoView from '../module/candidato/view/candidato.vue'

const routes = [
  {
    path: '/candidato/:candidato/documentos',
    name: 'candidato',
    component: CandidatoView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
